<template>
  <div>
    <div class="tab">
      <div @click="tab(0)" :class="active == 0 ? 'item active' : 'item'">
        全部
      </div>
      <div @click="tab(1)" :class="active == 1 ? 'item active' : 'item'">
        已回答
      </div>
      <div @click="tab(2)" :class="active == 2 ? 'item active' : 'item'">
        未回答
      </div>
    </div>
    <div class="list" v-if="list.length > 0">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="quiz">
          <div class="quiz_1">提问:</div>
          <div class="quiz_2">
            {{ item.question }}
          </div>
          <div class="quiz_3" @click="detail(item)">查看详情></div>
        </div>
        <div class="quiztime">
          &nbsp;&nbsp;提问时间：<span>{{ item.time }}</span>
        </div>
        <div class="answer">
          <div class="answer_1">回答:</div>
          <div class="answer_2">
            {{ item.answer ? item.answer : "暂无回答!" }}
          </div>
        </div>
        <div class="quiztime">
          &nbsp;&nbsp;回答时间：<span>{{
            item.answer_time ? item.answer_time : ""
          }}</span>
        </div>
      </div>
    </div>
    <div v-if="list.length == 0" style="text-align: center">
      <img class="img" src="../../assets/kong_yemian_imgs.png" alt="" />
    </div>
    <div v-if="list.length == 0" class="txt">暂无内容</div>
    <div class="btn" @click="quiz">我要提问></div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      active: 0,
      page: 1,
      list: [],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      classid: (state) => state.classid,
    }),
  },
  methods: {
    ...mapActions(["getMineQuestionList"]),
    quiz() {
      this.$router.push("/Layoutstudy/quiz");
    },
    detail(item) {
      this.$router.push({
        path: "/Layoutstudy/quizdetail",
        query: {
          id: item.id,
        },
      });
    },
    tab(num) {
      this.active = num;
      this.getMineQuestionList({
        page: this.page,
        type: this.active,
        name: "",
        username: this.userInfo.username,
        class: this.classid,
      }).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
        } else {
          this.list = [];
        }
      });
    },
  },
  mounted() {
    this.getMineQuestionList({
      page: this.page,
      type: this.active,
      name: "",
      username: this.userInfo.username,
      class: this.classid,
    }).then((res) => {
      if (res.code == 200) {
        this.list = res.data;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.tab {
  display: flex;
  height: 77px;
  line-height: 77px;
  justify-content: space-around;
  border-bottom: 1px solid #e1e1e1;
  .item {
    cursor: pointer;
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #a7a7a7;
  }
  .active {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    &::before {
      position: absolute;
      display: inline-block;
      content: "";
      width: 56px;
      height: 4px;
      background: #137cfb;
      bottom: -3px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.list {
  .item {
    margin-top: 45px;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 15px;
    .quiz {
      display: flex;
      width: 400px;
      line-height: 18px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      .quiz_1 {
        padding: 2px 5px;
        background-color: #137cfb;
        color: #fff;
      }
      .quiz_2 {
        flex: 1;
        color: #7d7d7d;
        margin-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .quiz_3 {
        cursor: pointer;
        padding: 2px 5px;
        background-color: #137cfb;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .quiztime {
      margin-top: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
      span {
        color: #cacaca;
      }
    }
    .answer {
      margin-top: 10px;
      display: flex;
      line-height: 16px;
      font-size: 14px;
      .answer_1 {
        padding: 2px 5px;
        background-color: #d9eaff;
        color: #137cfb;
        line-height: 14px;
        font-size: 14px;
      }
      .answer_2 {
        margin-left: 5px;
        color: #7d7d7d;
      }
    }
  }
}
.img {
  margin-top: 30px;
}
.txt {
  text-align: center;
  font-size: 16px;
  margin: 15px 0;

  color: #7d7d7d;
}
.btn {
  cursor: pointer;
  margin-top: 44px;
  width: 116px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  background: #137cfb;
  border-radius: 1px;
}
</style>
